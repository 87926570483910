<template>
  <v-app>
    <v-container id="vote-record-detail" fluid tag="section">
      <v-row justify="center">
        <dialog-message
          :dialog_message="dialog_message"
          :message="message"
        ></dialog-message>
        <v-col cols="12">
          <base-material-card>
            <template v-slot:heading>
              <div class="text-center text-h2 font-weight-medium">
                {{ meeting.urban_name }}
              </div>
              <div class="text-center text-h2 font-weight-medium">
                {{ meeting.title }}
              </div>
            </template>
            <v-col cols="12" class="text-center text-h2"
              >{{ $t("vote.issueTitle") }}{{ $t("signIn.title") }}</v-col
            >
            <v-col cols="12" class="text-center text-h2">{{
              TransDate(current_time)
            }}</v-col>
            <v-divider class="mx-4"></v-divider>
            <v-simple-table class="record_table py-2">
              <template v-slot:default>
                <thead class="positive_vote">
                  <tr>
                    <th
                      class="text-center text-h3 pa-6 table-title"
                      rowspan="2"
                    ></th>
                    <th
                      class="text-center text-h3 pa-6"
                      colspan="2"
                      style="color: white !important"
                    >
                      {{ $t("vote.land") }}
                    </th>
                    <th
                      class="text-center text-h3 pa-6"
                      colspan="2"
                      style="color: white !important"
                    >
                      {{ $t("vote.building") }}
                    </th>
                    <th
                      class="text-center text-h3 pa-6"
                      colspan="2"
                      style="color: white !important"
                    >
                      {{ $t("signIn.sign_owner_count") }}
                    </th>
                  </tr>
                  <tr>
                    <th
                      class="text-center text-h3 pa-2"
                      style="color: white !important"
                    >
                      <div>{{ $t("signIn.area") }}</div>
                      <div>{{ $t("signIn.area_unit") }}</div>
                    </th>
                    <th
                      class="text-center text-h3 pa-2"
                      style="color: white !important"
                    >
                      {{ $t("signIn.rate") }}
                    </th>
                    <th
                      class="text-center text-h3 pa-2"
                      style="color: white !important"
                    >
                      <div>{{ $t("signIn.area") }}</div>
                      <div>{{ $t("signIn.area_unit") }}</div>
                    </th>
                    <th
                      class="text-center text-h3 pa-2"
                      style="color: white !important"
                    >
                      {{ $t("signIn.rate") }}
                    </th>
                    <th
                      class="text-center text-h3 pa-2"
                      style="color: white !important"
                    >
                      {{ $t("vote.owners_count") }}
                    </th>
                    <th
                      class="text-center text-h3 pa-2"
                      style="color: white !important"
                    >
                      {{ $t("signIn.rate") }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="text-center text-h3 pa-6">
                      {{ $t("signIn.total") }}
                    </td>
                    <td class="text-center text-h3 pa-6">
                      {{ land_area.toFixed(2) }}
                    </td>
                    <td class="text-center text-h3 pa-6">
                      --
                    </td>
                    <td class="text-center text-h3 pa-6">
                      {{ building_area.toFixed(2) }}
                    </td>
                    <td class="text-center text-h3 pa-6">
                      --
                    </td>
                    <td class="text-center text-h3 pa-6">
                      {{ owner_count }}
                    </td>
                    <td class="text-center text-h3 pa-6">
                      --
                    </td>
                  </tr>
                  <tr v-for="(item, item_index) in result">
                    <td
                      class="text-center text-h3 pa-6 table-title"
                      :style="{ width: '9%' }"
                    >
                      {{ getTitle(item.status) }}數
                    </td>
                    <td class="text-center text-h3 pa-6">
                      <div>{{ item.land.toFixed(2) }}</div>
                    </td>
                    <td class="text-center text-h3 pa-6">
                      <div>{{ ((item.land / land_area)*100).toFixed(2) }}%</div>
                    </td>
                    <td class="text-center text-h3 pa-6">
                      <div>{{ item.building.toFixed(2) }}</div>
                    </td>
                    <td class="text-center text-h3 pa-6">
                      <div>{{ ((item.building / building_area)*100).toFixed(2) }}%</div>
                    </td>
                    <td class="text-center text-h3 pa-6">
                      <div>{{ item.owner_count }}</div>
                    </td>
                    <td class="text-center text-h3 pa-6">
                      <div>{{ ((item.owner_count / owner_count)*100).toFixed(2) }}%</div>
                    </td>
                  </tr>
                  <tr>
                    <td class="text-center text-h3 pa-6">
                      {{ $t("signIn.signInRate") }}
                    </td>
                    <td class="text-center text-h3 pa-6">
                      {{ signIn_land_area.toFixed(2) }}
                    </td>
                    <td class="text-center text-h3 pa-6">
                      {{ ((signIn_land_area / land_area)*100).toFixed(2) }}%
                    </td>
                    <td class="text-center text-h3 pa-6">
                      {{ signIn_building_area.toFixed(2) }}
                    </td>
                    <td class="text-center text-h3 pa-6">
                      {{ ((signIn_building_area / building_area)*100).toFixed(2) }}%
                    </td>
                    <td class="text-center text-h3 pa-6">
                      <div>{{ signIn_owner_count }}</div>
                    </td>
                    <td class="text-center text-h3 pa-6">
                      <div>{{ ((signIn_owner_count / owner_count)*100).toFixed(2) }}%</div>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </base-material-card>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import { signInType } from "@/definition.js";

export default {
  data: (vm) => ({
    dialog_message: false,
    issue: { title: null },
    meeting: { meeting_time: null, urban_renewal: { name: null } },
    positive_count: 3,
    alternates_count: 0,
    result: [],
    owners: [],
    signIns: [],
    options: [
      {
        text: vm.$i18n.t("signIn.personal"),
        value: 1,
      },
      {
        text: vm.$i18n.t("signIn.commission"),
        value: 2,
      },
      {
        text: vm.$i18n.t("signIn.cancel"),
        value: 3,
      },
    ],
    table_title: signInType,
    building_relation: [],
    land_relation: [],
    signInRecord: [],
    building_area: 0,
    land_area: 0,
    building_owner: 0,
    land_owner: 0,
    owner_count: 0,
    // signIn_land_owner: 0,
    // signIn_building_owner: 0,
    signIn_land_area: 0,
    signIn_building_area: 0,
    signIn_owner_count: 0,
    issue_id: "",
    meeting_id: "",
    message: {
      title: "",
      text: "",
    },
    current_time: new Date(),
  }),
  computed: {
    ...mapState(["token"]),
  },
  methods: {
    ...mapMutations(["setTitle"]),
    getApi() {
      this.loading = true;
      const currentObj = this;
      // const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      const url =
        process.env.VUE_APP_SERVER_URL + "/backend/signInResult/sign_in/";
      let config = {
        headers: {
          "Content-Type": "application/json",
          // Authorization: "Token " + this.token
        },
        params: {
          meeting: this.meeting_id,
        },
      };

      this.axios
        .get(url, config)
        .then(function (response) {
          currentObj.loading = false;
          currentObj.setResult(response.data);
        })
        .catch(function (error) {
          console.log(error);
          console.log(error.response);
          currentObj.loading = false;
          currentObj.message.title = currentObj.$t("get-api-fail");
          currentObj.message.text = JSON.stringify(error.response.data);
          currentObj.dialog_message = !currentObj.dialog_message;
        });
    },
    setResult(data) {
      let info = JSON.parse(JSON.stringify(data));
      //   this.totalItems = info.options.length;
      this.meeting = info.meeting;
      this.signInRecord = [];
      this.building_area = 0;
      this.land_area = 0;
      this.owners = info.owners;
      this.signIns = info.sign_ins;
      this.getOwnerInfo();
      this.setVoteRecord();
    },
    setVoteRecord() {
      this.signInRecord = [];
      this.building_area = 0;
      this.land_area = 0;
      this.building_owner = 0;
      this.land_owner = 0;
      this.owner_count = 0;
      this.signIn_owner_count = 0;
      // this.signIn_land_owner = 0;
      // this.signIn_building_owner = 0;
      this.signIn_land_area = 0;
      this.signIn_building_area = 0;
      this.owners.forEach((owner) => {
        this.owner_count +=1;
        let building_area = this.getBuilding(owner);
        let land_area = this.getLand(owner);
        if (building_area != 0) {
          this.building_owner += 1;
          this.building_area += building_area;
        }
        if (land_area != 0) {
          this.land_owner += 1;
          this.land_area += land_area;
        }
      });
      this.signIns.forEach((sign_in) => {
        if (sign_in.status != 3) {
          let owner = this.owners.find((item) => {
            return item.id == sign_in.owner;
          });
          if (owner) {
            let building = this.getBuilding(owner);
            let land = this.getLand(owner);
            this.calculateVoteRecord(sign_in, building, land);
          }
        }
      });
      this.signInRecord.sort(function (a, b) {
        return a.status - b.status;
      });
      this.result = this.signInRecord.slice(
        0,
        this.positive_count + this.alternates_count
      );
    },
    getOwnerInfo() {
      let ownerInfo = this.meeting.ownerInfo;
      this.ownerInfo = JSON.parse(ownerInfo);
    },
    getBuilding(owner) {
      let owner_item = this.ownerInfo.find((element) => {
        return element.owner == owner.id;
      });
      return owner_item.building_area;
    },
    getLand(owner) {
      let owner_item = this.ownerInfo.find((element) => {
        return element.owner == owner.id;
      });
      return owner_item.land_area;
    },
    calculateVoteRecord(signIn, building, land) {
      let record = this.signInRecord.find((record) => {
        return record.status == signIn.status;
      });
      let hasBuilding = true;
      let hasLand = true;
      if (building == 0) {
        hasBuilding = false;
      }
      if (land == 0) {
        hasLand = false;
      }
      if (record) {
        if (hasBuilding) {
          // this.signIn_building_owner += 1;
          this.signIn_building_area += building;
          // record.building_owner += 1;
          record.building += building;
        }
        if (hasLand) {
          // this.signIn_land_owner += 1;
          this.signIn_land_area += land;
          // record.land_owner += 1;
          record.land += land;
        }
        record.owner_count += 1;
        this.signIn_owner_count += 1;
      } else {
        let option = this.options.find((item) => {
          return item.value == signIn.status;
        });
        let new_result = {
          building: building,
          land: land,
          status: signIn.status,
          option_name: option.text,
          owner_count: 1
        };
        // if (hasBuilding) {
          // this.signIn_building_owner += 1;
        this.signIn_building_area += building;
          // new_result.building_owner = 1;
        // } else {
          // new_result.building_owner = 0;
        // }
        // if (hasLand) {
          // this.signIn_land_owner += 1;
        this.signIn_land_area += land;
          // new_result.land_owner = 1;
        // } else {
          // new_result.land_owner = 0;
        // }
        this.signIn_owner_count += 1;
        this.signInRecord.push(new_result);
      }
    },
    receiveSocketData(message) {
      let info = JSON.parse(message.data);
      let new_vote = info.data;
      if (info.action == 2) {
        let vote = this.signIns.find((item) => {
          return item.id == new_vote.id;
        });
        if (vote) {
          vote.status = new_vote.status;
        } else {
          this.signIns.push(new_vote);
        }
        this.setVoteRecord();
      }
    },
    getCurrentTime() {
      this.current_time = new Date();
    },
    getTitle(status){
      let title = "";
      switch (status) {
        case this.table_title.personal:
          title = this.$i18n.t("signIn.personal")
          break;
        case this.table_title.commission:
          title = this.$i18n.t("signIn.commission")
          break;
      }
      return title
    }
  },
  mounted() {
    this.$options.sockets.onmessage = this.receiveSocketData;
    this.setTitle(this.$i18n.t("signIn.title"));
    this.meeting_id = this.$route.query.meeting;
    this.getApi();
    setInterval(() => this.getCurrentTime(), 1000);
  },
  beforeDestroy() {
    delete this.$options.sockets.onmessage;
  },
};
</script>

<style lang="sass">
@import '~vuetify/src/styles/tools/_rtl.sass'
.table-title
    width: 5%

.record_table
    .v-data-table__wrapper
    thead
        &.positive_vote
        th
            background-color: #2699FB
    tbody
        th,td
            width: 14.2%
    th,td
        font-size: 16px
        border: 1px solid rgba(0,0,0, 0.6)
        border-top: 2px solid rgba(0,0,0, 0.6)
        .td-left
            border-right: 2px solid rgba(0,0,0, 0.6)
        &:first-child
            border-left: 2px solid rgba(0,0,0, 0.6)
        &:last-child
            border-right: 2px solid rgba(0,0,0, 0.6)
    tr
        &:last-child
        td
            border-bottom: 2px solid rgba(0,0,0, 0.6)
</style>
